import { ComponentType } from "react";
import { GlobalPermissions } from "@shipin/shipin-app-server-client";

import { lazyWithRetry } from "./lazyrefresh";
import { isEdge } from "utils";

type Routes =
  | "homeDashboard"
  | "vessels"
  | "sensors"
  | "logout"
  | "fleets"
  | "welcome"
  | "userprofile"
  | "activitiesdashboard"
  | "reports"
  | "errors"
  | "users"
  | "detectors"
  | "performanceAnalytics";

export interface Path {
  /** Route component props */
  path: string;
  component: ComponentType<any>;
  exact: boolean;
  /** Is path accessible without user authentication. */
  isPrivate?: boolean;
  /** Required permissions to open the route */
  requiredPermissions?: Array<keyof GlobalPermissions>;
}
const routes: Record<Routes, Path> = {
  homeDashboard: {
    // Do not open dashboard on edge
    path: isEdge ? "/activities" : "/",
    component: !isEdge ? lazyWithRetry(() => import("../pages/Dashboard/")) : lazyWithRetry(() => import("../pages/ActivitiesDashboard")),
    exact: true,
  },
  vessels: {
    path: "/vessels",
    component: lazyWithRetry(() => import("../pages/VesselList")),
    exact: true,
  },
  sensors: {
    path: "/sensors",
    // When on edge, load sensors page which uses GRPC APIs
    component: isEdge ? lazyWithRetry(() => import("../pages/SensorsEdge/SensorsEdge")) : lazyWithRetry(() => import("../pages/Sensors")),
    exact: true,
  },
  fleets: {
    path: "/fleets",
    component: lazyWithRetry(() => import("../pages/FleetDashboard/Fleets")),
    exact: true,
  },
  userprofile: {
    path: "/user-profile",
    component: lazyWithRetry(() => import("../pages/MyProfile/MyProfile.page")),
    exact: false,
  },
  activitiesdashboard: {
    path: "/activities",
    component: lazyWithRetry(() => import("../pages/ActivitiesDashboard")),
    exact: true,
  },
  performanceAnalytics: {
    path: "/performance-analytics",
    component: lazyWithRetry(() => import("../pages/PerformanceAnalytics/PerformanceAnalytics")),
    exact: true,
  },
  reports: {
    path: "/reports",
    component: lazyWithRetry(() => import("../pages/Reports")),
    exact: false,
  },
  errors: {
    path: "/error",
    component: lazyWithRetry(() => import("../pages/Errors")),
    exact: false,
    isPrivate: true,
  },
  users: {
    path: "/users",
    component: lazyWithRetry(() => import("../pages/Users/Users")),
    exact: false,
    requiredPermissions: ["can_view_all_users"],
  },
  detectors: {
    path: "/detectors",
    component: lazyWithRetry(() => import("../pages/Detectors")),
    exact: true,
    isPrivate: false,
    requiredPermissions: ["can_view_detectors"],
  },
  welcome: {
    path: "/welcome",
    component: lazyWithRetry(() => import("../pages/Welcome/Welcome")),
    exact: true,
    isPrivate: true,
  },
  logout: {
    path: "/logout",
    component: lazyWithRetry(() => import("../pages/Logout/Logout")),
    exact: true,
    isPrivate: true,
  },
};

export default routes;
