import { MouseEventHandler, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { NavLink as Link, useLocation, useRouteMatch } from "react-router-dom";
import { Box } from "@material-ui/core";

import { isEdge } from "utils";
import { useCurrentUser } from "queries";

import { HeaderContainer, HeaderProfile, HeaderNavMenu, HeaderAction, HeaderLogo, HeaderActionIcon, CollapseStyle } from "./Header.styles";
//import Notifications from "../Icon/Notifications";
import ChevronIcon from "../Icon/ChevronIcon";
import Logo from "../Icon/Logo";
import routes from "../../config/routes";
import Refresh from "components/Icon/Refresh";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { manualRefresh, refreshManual } from "services/redux/reducers/RefreshTimer/RefreshTimer.slice";
import { images } from "config/images";
import Skeleton from "components/Loaders/Skeleton";
import ProfileMenu from "./ProfileMenu";
// import Message from "components/Icon/Message";
import { HeaderCrumb } from "./Header.types";
import Submenu from "./Submenu";
import { reportsRoutes } from "pages/Reports/reports.routes";
import { RBACRenderer } from "components/Auth";
import NotificationsPanel from "./NotificationsPanel";
import { Tooltip } from "components/Tooltip/Tooltip.styles";
import ShipShape from "./ShipShape";

const crumbs: Array<HeaderCrumb> = [
  {
    label: "Dashboard",
    path: routes.homeDashboard.path,
  },
  {
    label: "Activities",
    path: routes.activitiesdashboard.path,
    visible: true,
  },
  {
    label: "Fleets",
    path: routes.fleets.path,
  },
  {
    label: "Vessels",
    path: routes.vessels.path,
  },
  {
    label: "Sensors",
    path: routes.sensors.path,
    visible: true,
  },
  {
    label: "Reports",
    path: routes.reports.path,
    submenu: [
      {
        sublabel: "Bridge Watch - Vessel",
        sublinkpath: reportsRoutes.bridgeWatch.path,
      },
      {
        sublabel: "Bridge Watch - Fleet",
        sublinkpath: reportsRoutes.fleetReport.path,
      },
    ],
  },
];

const NavItem = (
  props: Omit<HeaderCrumb, "visible"> & {
    onLinkClick?: MouseEventHandler<HTMLAnchorElement>;
  }
) => {
  const { label, path, submenu, exact = true, onLinkClick } = props;
  const location = useLocation();

  if (!!submenu && !!submenu?.length) {
    return <Submenu label={label} path={path} submenu={submenu} key={location.pathname} />;
  }

  return (
    <li className="nav__item">
      <Link exact={exact} activeClassName="nav__item_link_active" className="nav__item_link" color="inherit" to={path} onClick={onLinkClick}>
        {label}
      </Link>
    </li>
  );
};

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const hours = time.getUTCHours().toString().padStart(2, "0");
  const minutes = time.getUTCMinutes().toString().padStart(2, "0");
  const seconds = time.getUTCSeconds().toString().padStart(2, "0");

  return (
    <Tooltip title="Current UTC" placement="top-end" arrow>
      <Box fontSize="14px" mr={1.75}>
        {hours}:{minutes}:{seconds} UTC
      </Box>
    </Tooltip>
  );
};

const Header = () => {
  const match = useRouteMatch();
  const isFullScreen = useAppSelector((state) => state.dashboard.fullScreen);
  const dispatch = useAppDispatch();

  const client = useQueryClient();
  const { data: userInfo, isLoading } = useCurrentUser();

  const handleRefreshClick = () => {
    dispatch(manualRefresh());
    // Increase separate counter which triggers manual refresh
    dispatch(refreshManual());
    client.invalidateQueries();
  };

  return (
    <CollapseStyle
      timeout={100}
      className={match.path === routes.homeDashboard.path && !isEdge ? "dashboard-header" : ""}
      in={isFullScreen ? false : true}
    >
      <HeaderContainer>
        <div className="header">
          <HeaderLogo>
            <Link to="/">
              <Logo />
            </Link>
          </HeaderLogo>
          <HeaderNavMenu>
            <ul className="nav">
              {/* Defaulting visible to true when in shore mode,
              we need to show activities and sensors only when in edge */}
              {crumbs.map(({ visible = !isEdge, ...crumbProps }) => {
                return visible ? <NavItem key={crumbProps.path} {...crumbProps} /> : null;
              })}
              {!isEdge && (
                <>
                  <NavItem label="Analytics" path={routes.performanceAnalytics.path} />
                  <RBACRenderer requiredPermissions={["can_view_all_users"]}>
                    <NavItem
                      label="Users"
                      /* Adding users base path, adding link to /users/active will not  
                  make link active when subroutes /suspended and /pending are active */
                      path={routes.users.path}
                      exact={false}
                      onLinkClick={(e) => {
                        // Prevent navigating if user is already on users page
                        // We need to do this becuase users page has subroutes
                        if (match.path === routes.users.path) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </RBACRenderer>
                  <RBACRenderer requiredPermissions={["can_view_detectors"]}>
                    <NavItem label="Detectors" path={routes.detectors.path} />
                  </RBACRenderer>
                </>
              )}
            </ul>
          </HeaderNavMenu>
          <HeaderAction>
            <Clock />
          </HeaderAction>
          {!isEdge ? <NotificationsPanel /> : null}
          <Tooltip placement="top-end" arrow title="Refresh">
            <HeaderActionIcon aria-label="Filter" onClick={handleRefreshClick}>
              <Refresh />
            </HeaderActionIcon>
          </Tooltip>
          <ShipShape />
          {/* <HeaderActionIcon aria-label="Filter">
            <Message />
          </HeaderActionIcon> */}
          {!isEdge ? (
            <HeaderProfile>
              <ProfileMenu>
                <Skeleton loading={isLoading} height="32px" width="162px">
                  <span className="profile-thumb">
                    {userInfo?.image_url ? (
                      <img
                        src={userInfo.image_url}
                        alt={userInfo.username ?? ""}
                        onError={(e) => (e.currentTarget.src = images.placeholderProfile)}
                      />
                    ) : (
                      <img src={images.placeholderProfile} alt="Profile" />
                    )}
                  </span>
                  {/* {userInfo?.full_name ?? userInfo?.username} */}
                </Skeleton>
                <ChevronIcon />
              </ProfileMenu>
            </HeaderProfile>
          ) : null}
        </div>
      </HeaderContainer>
    </CollapseStyle>
  );
};

export default Header;
