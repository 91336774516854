import { ComponentPropsWithoutRef, ReactNode } from "react";

import { FilterButtonRoot } from "./FilterButton.styles";

const Arrow = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L6 7L11 1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

interface FilterButtonProps extends ComponentPropsWithoutRef<"button"> {
  withArrow?: boolean;
  active?: boolean;
  count?: number;
  leftIcon?: ReactNode;
}
const FilterButton = (props: FilterButtonProps) => {
  const { withArrow = false, active = false, children, className, count, leftIcon = null, ...rest } = props;

  return (
    <FilterButtonRoot
      {...rest}
      aria-label={typeof props.children === "string" ? props.children : undefined}
      className={[className, active ? "active" : "", !!count && count !== 0 ? "hasCount" : ""].join(" ").trim()}
    >
      {leftIcon}
      <span className="filter-button-content">{children}</span>
      {!!count && count !== 0 && <span className="count">({count})</span>}
      {withArrow && <Arrow />}
    </FilterButtonRoot>
  );
};

export { FilterButton };
